<template>
  <div class="AI-Smart-Room-pc">
      <div class="pc-header">
          <div class="pc-header-content">
              <img src="../images/pc/AI-logo.png">
              <el-tabs v-model="activeName">
                  <el-tab-pane label="产品介绍" name="productIntro"></el-tab-pane>
                  <el-tab-pane label="招商加盟" name="joinUs"></el-tab-pane>
              </el-tabs>
          </div>
      </div>
      <el-scrollbar ref="scrollbar">
          <div v-if="activeName=='productIntro'" class="content">
              <img v-for="(item,index) in productIntroImages" :key="index" :src="item" width="100%" height="auto">
          </div>
          <div v-else-if="activeName=='joinUs'" class="content">
              <img v-for="(item,index) in joinUsImages" :key="index" :src="item" width="100%" height="auto">
          </div>
      </el-scrollbar>
      <div ref="qrRef" class="qr-container" :style="qrPos">
          <div>期待与您合作</div>
          <img src="../images/qrcode.png" width="144px" height="144px">
          <div class="scan-code">扫码添加合作顾问</div>
      </div>
  </div>
</template>

<script>

export default {
    name: 'pc',
    data () {
        return {
            activeName: 'productIntro',
            productIntroImages: [
                require('../images/pc/zh_01.jpg'),
                require('../images/pc/zh_02.jpg'),
                require('../images/pc/zh_03.jpg'),
                require('../images/pc/zh_04.jpg'),
                require('../images/pc/zh_05.jpg'),
                require('../images/pc/zh_06.jpg'),
                require('../images/pc/zh_07.jpg'),
                require('../images/pc/zh_08.jpg'),
                require('../images/pc/zh_09.jpg'),
                require('../images/pc/zh_10.jpg'),
                require('../images/pc/zh_11.jpg'),
                require('../images/pc/zh_12.jpg'),
                require('../images/pc/zh_13.jpg'),
                require('../images/pc/zh_14.jpg'),
                require('../images/pc/zh_15.jpg'),
                require('../images/pc/zh_16.jpg'),
                require('../images/pc/zh_17.jpg'),
            ],
            joinUsImages: [
                require('../images/pc/zs_01.jpg'),
                require('../images/pc/zs_02.jpg'),
                require('../images/pc/zs_03.jpg'),
                require('../images/pc/zs_04.jpg'),
                require('../images/pc/zs_05.jpg'),
                require('../images/pc/zs_06.jpg'),
                require('../images/pc/zs_07.jpg?v=20240703'),
            ],
            qrPos: {
                left: 'unset',
                top: 'unset',
                right: '10px',
                bottom: '40px',
            },
        };
    },
    watch: {
        activeName (val) {
            const scrollWarp = document.querySelector('.AI-Smart-Room-pc .el-scrollbar__wrap');
            if (scrollWarp) {
                scrollWarp.scrollTop = 0;
            }
        },
    },
    mounted () {
        this.$nextTick(() => {
            this.$refs.qrRef.addEventListener('mousedown', this.onStartDrag);
        });
    },
    beforeDestroy () {
        this.$refs.qrRef.removeEventListener('mousedown', this.onStartDrag);
    },
    methods: {
        onStartDrag (e) {
            let touch = e;
            document.body.addEventListener('mousemove', this.onDrag);
            document.body.addEventListener('mouseup', this.onEndDrag);
            this.bodyRect = document.body.getBoundingClientRect();
            this.startDragPoint = { x: touch.clientX, y: touch.clientY, dx: this.$refs.qrRef.offsetLeft, dy: this.$refs.qrRef.offsetTop };
        },
        onDrag (e) {
            let touch = e;
            const nx = touch.clientX - this.startDragPoint.x;
            const ny = touch.clientY - this.startDragPoint.y;
            let x = this.startDragPoint.dx + nx;
            let y = this.startDragPoint.dy + ny;
            if (x < 0) x = 0;
            if (x > this.bodyRect.width - 162) x = this.bodyRect.width - 162;
            if (y < 0) y = 0;
            if (y > this.bodyRect.height - 225) y = this.bodyRect.height - 225;
            Object.assign(this.qrPos, {
                left: x + 'px',
                top: y + 'px',
                right: 'unset',
                bottom: 'unset',
            });
        },
        onEndDrag (e) {
            document.body.removeEventListener('mousemove', this.onDrag);
            document.body.removeEventListener('mouseup', this.onEndDrag);
        },
    },
};
</script>

<style scoped lang="scss">
.AI-Smart-Room-pc {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .pc-header {
        padding: 15px 0px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 8px 0 hsla(0, 0%, 78.4%, .4);
        position: relative;
        z-index: 1;
        .pc-header-content {
            max-width: 1200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            ::v-deep .el-tabs__header {
                margin-bottom: 4px;
                .el-tabs__nav-wrap {
                    .el-tabs__nav {
                        .el-tabs__active-bar {
                            background-color: #FFC136;
                            height: 3px;
                            left: 10px;
                            right: 10px;
                            width: 44px !important;
                        }
                        .el-tabs__item {
                            color: #242424;
                            font-size: 16px;
                            &.is-active {
                                color: #FFC136;
                            }
                        }
                    }
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    ::v-deep .el-scrollbar__bar {
        .el-scrollbar__thumb {
            background-color: rgba(0, 0, 0, .4);
        }
    }
    .content {
        width: 100%;
        flex: 1;
        overflow-y: auto;
        >img {
            display: block;
            //max-width: 1920px;
            //margin: 0 auto;
        }
    }
    .qr-container {
        position: fixed;
        z-index: 2;
        right: 10px;
        bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 162px;
        height: 225px;
        background: linear-gradient(177deg, #FFA72B, #FF8415);
        box-shadow: 4px 7px 16px 0px rgba(255,133,22,0.3);
        border-radius: 12px;
        padding: 10px 0px;
        color: #FFFFFF;
        font-size: 15px;
        user-select: none;
        * {
            pointer-events: none;
        }
        .scan-code {
            font-size: 12px;
        }
    }
}
</style>
