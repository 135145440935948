<template>
  <div class="AI-Smart-Room">
      <pc v-if="!isMobile"></pc>
      <mobile v-else></mobile>
  </div>
</template>

<script>
import pc from './components/pc.vue';
import mobile from './components/mobile.vue';

export default {
    name: 'AISmartRoom',
    components: { pc, mobile },
    data () {
        return {};
    },
    computed: {
        isMobile () {
            return (/Mobi|Android|iPhone/i.test(navigator.userAgent));
        },
    },
    mounted () {
        document.title = '优学派AI智习室';
        document.getElementById('viewport').content = 'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no>';
        document.getElementsByTagName('body')[0].style.zoom = 1;
    },
};
</script>

<style lang="scss">
//@import "@style/variable.scss";
html,body {
    touch-action: none;
    overflow: hidden;
}
#yxp_website.AISmartRoom{
    padding-top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-width: 0;
    .header-out,.footer-out{
        display: none;
    }
    .content-out{
        padding-bottom: 0;
        height: 100%;
    }
}
</style>
